import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import "./AboutMePage.css";
import Layout from "../layout/Layout";

const AboutMePage: React.FC = () => {
  const navigate = useNavigate();
  const handleNavigation = (urlPath: string) => {
    navigate(urlPath);
  };
  return (
    <Layout>
      <div className="aboutMeContainer">
        <Button text="Home" onClick={() => handleNavigation("/")} />
        <h2 className="aboutMeHeading">About me!</h2>
        <p className="aboutMeText">
          I am a software engineer who loves to code and build things. I am
          passionate about web development, and I love to learn new things. I am
          always looking for new opportunities to grow and expand my knowledge.
        </p>
      </div>
    </Layout>
  );
};

export default AboutMePage;
