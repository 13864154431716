import { useState } from "react";
import Button from "../../components/button/Button";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import "./HomePage.css";

const HomePage: React.FC = () => {
  const [showIntro, setShowIntro] = useState(true);
  const navigate = useNavigate();

  const handleNavigation = (urlPath: string) => {
    navigate(urlPath);
  };

  const toggleIntro = () => {
    setShowIntro(!showIntro);
  };

  return (
    <Layout>
      <div className="HomePage">
        {showIntro ? (
          <div className="intro-section">
            <h1 className="greeting-text">Hi, I'm Don!</h1>
            <p className="intro-text">
              I am a software engineer who loves to code and build things. I am
              passionate about web development, and I love to learn new things.
              I am always looking for new opportunities to grow and expand my
              knowledge.
            </p>
            <Button onClick={toggleIntro} text="Next" icon={faArrowRight} />
          </div>
        ) : (
          <div className="main-section">
            <Button onClick={toggleIntro} text="Back" icon={faArrowLeft} />
            <p className="question-text">What are you looking for?</p>
            <div className="button-group">
              <Button
                text={`About Me`}
                onClick={() => handleNavigation("/about-me")}
              />
              {/* HIDING GAME BUTTON FOR NOW */}
              {/* <Button text={`Game`} onClick={() => handleNavigation("/game")} /> */}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default HomePage;
